//#region Imports

//#endregion

import { Injectable } from '@angular/core';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

@Injectable({
  providedIn: 'root',
})
export class SingleAccessInteractor {

  //#region Constructors

  constructor(
    private readonly http: HttpAsyncService,
  ) {}

  //#endregion

  //#region Functions

  public async verifySigleAccessCode(): Promise<AsyncResult<void>> {
    const url = '/user/access/check';
    return await this.http.get(url);
  }

  public async setNewAccessCode(code: string): Promise<AsyncResult<void>> {
    const url = '/user/access/new/';
    return await this.http.put(url + encodeURI(code), {});
  }

  //#endregion
}
