//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MenuUqrCoursesProxy } from '../../models/proxies/menu-uqr-courses.proxy';
import { MenuUqrTracksProxy } from '../../models/proxies/menu-uqr-tracks.proxy';
import { MenuUqrProxy } from '../../models/proxies/menu-uqr.proxy';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class MenuUQRInteractor {
//#region Constructors

  constructor(
    private readonly http: HttpAsyncService,
  ) {}

  //#endregion

  //#region Functions

  public async listMenus(): Promise<AsyncResult<MenuUqrProxy[]>> {
    return await this.http.get<MenuUqrProxy[]>(environment.routes.menus);
  }

  public async getMenuById(menuId: string, limit: number): Promise<AsyncResult<MenuUqrProxy>> {
    return await this.http.get<MenuUqrProxy>(`/menuuqr/${ menuId }?limit=${limit}`);
  }

  public async getCoursesByMenu(menuId: string): Promise<AsyncResult<MenuUqrCoursesProxy[]>> {
    return await this.http.get<MenuUqrCoursesProxy[]>(`/menuuqr/courses/filtered/${ menuId }`);
  }

  public async getTracksByMenu(menuId: string): Promise<AsyncResult<MenuUqrTracksProxy[]>> {
    return await this.http.get<MenuUqrTracksProxy[]>(`/menuuqr/tracks/filtered/${ menuId }`);
  }

  //#endregion

}
