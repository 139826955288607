//#region Imports

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/browser';
import decode from 'jwt-decode';

import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatAll, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { IJwtPayload } from '../../../models/payloads/jwt.payload';
import { TokenProxy } from '../../../models/proxies/token.proxy';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helper/helper.service';
import { StorageService } from '../../../services/storage/storage.service';

//#endregion

//#region Class

/**
 * Serviço que intercepta todas as requisições e verifica se ainda tem permissão para usar o app
 */
@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    private readonly helper: HelperService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly storage: StorageService,
  ) { }

  //#endregion

  //#region Methods

  /**
   * Método que intercepta a requisição
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const canPerformRequest$ = fromPromise(this.storage.getItem<TokenProxy>(environment.keys.token).then(result => result && result.success || void 0)).pipe(
      map(tokenInfo => {
        if (!tokenInfo)
          return of(true);

        const jwtPayload: IJwtPayload = decode(tokenInfo.token);
        const refreshJwtPayload: IJwtPayload = decode(tokenInfo.refreshToken);

        const fiveMinutesInMilliseconds = 1_000 * 60 * 10;
        const maxSafeExpiresDate = +new Date() + fiveMinutesInMilliseconds;

        const expiresIn = +new Date(jwtPayload.exp * 1000);
        const shouldRefreshToken = maxSafeExpiresDate >= expiresIn;

        if (!shouldRefreshToken)
          return of(true);

        const refreshExpiresIn = +new Date(refreshJwtPayload.exp * 1000);
        const maxSafeRefreshExpiresDate = +new Date() + fiveMinutesInMilliseconds;

        const canRefreshToken = maxSafeRefreshExpiresDate >= refreshExpiresIn;

        if (!canRefreshToken)
          return of(false);

        return fromPromise(this.tryRefreshToken(tokenInfo.refreshToken));
      }),
      concatAll(),
    );

    return canPerformRequest$.pipe(
      map(canPerform => {
        if (canPerform)
          return next.handle(req);

        throw new HttpErrorResponse({ error: { message: 'A sua sessão expirou, você precisa logar novamente.' }, status: 401 });
      }),
      concatAll(),
      catchError(error => {
        Sentry.setTags({
          statusCode: String(error.status),
        });
        Sentry.captureException(error);

        if (error.status !== 401)
          throw error;

        this.storage.getItem<TokenProxy>(environment.keys.token)
          .then(result => result && result.success || void 0)
          .then(shouldLogout => {
              if (!shouldLogout)
                return;

              this.auth.logout()
                .then(() => this.router.navigateByUrl('/login', { replaceUrl: true }))
                .then(() => this.helper.showToast('A sua sessão expirou, você precisa logar novamente.'));
            },
          );

        throw error;
      }),
    );
  }

  //#endregion

  //#region Private Methods

  /**
   * Método que realiza a renovação do token de autenticação atual utilizando o token de atualização
   *
   * @param refreshToken O token de renovação
   */
  private async tryRefreshToken(refreshToken: string): Promise<boolean> {
    return await fetch(`${ environment.apiBaseUrl }/auth/refresh`, {
      method: 'POST',
      headers: {
        Authorization: refreshToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as unknown as Request)
      .then(async result => result.ok ? ({ success: await result.json() as unknown as TokenProxy, error: undefined }) : ({ success: undefined, error: new HttpErrorResponse({ error: 'A sua sessão expirou, você precisa logar novamente.', status: 401 }) }))
      .catch(error => ({ error, success: undefined }))
      .then(async result => {
        if (result.error)
          return false;

        const { error: errorOnSaveToken } = await this.storage.setItem(environment.keys.token, result.success);

        if (errorOnSaveToken)
          return false;

        return true;
      });
  }

  //#endregion

}

//#endregion
