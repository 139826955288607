//#region Imports

import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { OriginSessionEnum } from './models/enums/origin-session.enum';
import { SessionPayload } from './models/interfaces/session.interface';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { HttpAsyncService } from './modules/http-async/services/http-async.service';
import { AuthService } from './services/auth/auth.service';

//#endregion

@Component({
  selector: 'uqr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    private readonly http: HttpAsyncService,
    private readonly userService: AuthService,
  ) {}

  //#endregion

  //#region Public Functions

  public async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      const user = this.userService.getCurrentUser();

      if (!user) {
        await this.ngOnInit();
        return;
      }

      const url: string = `${ environment.config.sessionApiUrl }/session?credentials=${ environment.config.sessionCredential }`;
      const payload: SessionPayload = {
        userName: user.name,
        userId: user.id,
        origin: OriginSessionEnum.UQR,
        timeMinutes: 2,
      };

      try {
        await this.http.getNativeClient().post(url, payload, {
          headers: new HttpHeaders({
            [BaseUrlInterceptor.DISABLE_HEADER]: 'true',
          }),
        }).toPromise();
      } catch (e) {
        console.log(e);
      }
      await this.ngOnInit();
    }, environment.config.sessionTimeout);
  }

  //#endregion

}
