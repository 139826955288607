//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuUQRInteractor } from '../../interactors/menuuqr/menu-uqr.interactor';
import { MenuUqrCoursesProxy } from '../../models/proxies/menu-uqr-courses.proxy';
import { MenuUqrTracksProxy } from '../../models/proxies/menu-uqr-tracks.proxy';
import { MenuUqrProxy } from '../../models/proxies/menu-uqr.proxy';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class MenuUQRService {

  //#region Constructors

  constructor(
    private readonly interactor: MenuUQRInteractor,
  ) {}

  //#endregion

  //#region Properties

  private readonly selectedMenu: BehaviorSubject<MenuUqrProxy> = new BehaviorSubject<MenuUqrProxy>(null);

  private readonly inputTextMenu: BehaviorSubject<string> = new BehaviorSubject<string>('');

  //#endregion

  //#region Functions

  public getCurrentInputTextMenu$(): Observable<string> {
    return this.inputTextMenu.asObservable();
  }

  public setCurrentInputTextMenu(currentMenu: string): void {
    this.inputTextMenu.next(currentMenu);
  }

  public getCurrentMenu$(): Observable<MenuUqrProxy> {
    return this.selectedMenu.asObservable();
  }

  public setCurrentMenu(currentMenu: MenuUqrProxy): void {
    this.selectedMenu.next(currentMenu);
  }

  public async listMenus(): Promise<MenuUqrProxy[]> {
    const { error, success } = await this.interactor.listMenus();

    if (error)
      return [];

    return success;
  }

  public async getCoursesByMenu(menuId: string): Promise<MenuUqrCoursesProxy[]> {
    const { error, success } = await this.interactor.getCoursesByMenu(menuId);

    if (error)
      throw new Error(error.message);

    return success;
  }

  public async getTracksByMenu(menuId: string): Promise<MenuUqrTracksProxy[]> {
    const { error, success } = await this.interactor.getTracksByMenu(menuId);

    if (error)
      throw new Error(error.message);

    return success;
  }

  //#endregion

}
