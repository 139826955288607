export const routes = {
  acceptTerms: '/users/acceptTerms',
  amountHours: '/users/change/amount/hours',
  annotation: '/users/annotations',
  annotationByLesson: '/users/annotations/lesson/{lessonId}',
  auth: '/auth/localUQR',
  changeAvatar: '/users/changeAvatar',
  changePassword: '/users/password/change',
  changeUser: '/users/changeUser',
  createCertificate: '/users/certificates',
  createUserTags: '/users/tags/createMany',
  forgotPassword: '/users/password/forgot/email/{email}',
  getCoursesTags: '/courses/tags',
  getOneLesson: '/lessons/{lessonId}?join[0]=mediaLessons',
  getOneQuiz: '/quizzes/{quizId}?join[0]=questions&join[1]=questions.answers&join[2]=module&join[3]=course&sort=questionsAnswers.createdAt,ASC',
  getQuizQuestions: '/quizzes/questions?quizId={quizId}',
  getValidQuiz: '/quizzes/valid/{quizId}?sort=questionsAnswers.createdAt,ASC',
  lesson: '/lessons',
  listRanking: '/users/progress/ranking-patent?page={page}&limit={limit}',
  listRankingByTrack: '/users/progress/getRankingUserByTrackId/{trackId}',
  markProgress: '/users/progress/lessons',
  markQuizAnswer: '/users/quizzes/answers',
  menus: '/menuuqr/getManyUQR',
  messages: '/lessons/messages?page=1&limit=1000&sort=createdAt,ASC&join[0]=sender&s={searchParams}',
  organization: '/organizations',
  patent: '/ranking/patent',
  quotes: '/quote',
  rankingMe: '/users/progress/ranking-patent/me',
  rankingTrack: '/users/progress/ranking/track/{trackId}',
  recomendation: '/users/recomendation',
  resetPassword: '/users/password/reset/{resetPasswordCode}',
  subject: '/courses',
  subjectOngoing: '/courses/ongoing',
  tags: '/tags',
  topTenRanking: '/users/topTenRanking',
  track: '/tracks',
  user: '/users',
  userCertificates: '/users/certificates/me',
  relevancies: '/relevancies',
  userLessonProgress: '/users/progress?s={searchParams}',
  userMe: '/users/me',
};
