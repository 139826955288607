//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';
import { StorageService } from '../../../services/storage/storage.service';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class PlatformInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    protected readonly authService: AuthService,
    protected readonly storage: StorageService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) { }

  //#endregion

  //#region Public Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-BearerToken';

  //#endregion

  //#region Public Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.headers.get(PlatformInterceptor.DISABLE_HEADER)) {
      const headers = req.headers.set('X-Platform', 'uqr');

      req = req.clone({
        headers,
      });

      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.delete(PlatformInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion

}
