//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../../environments/environment';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { AuthService } from '../../services/auth/auth.service';
import { StorageService } from '../../services/storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly auth: AuthService,
  ) { }

  //#endregion

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};

    if (!routeToRedirect)
      return true;

    await this.auth.setupAuthentication();
    let hasToken = await this.storage.getItem<TokenProxy>(environment.keys.token).then(result => !!result.success);

    if (!hasToken)
      hasToken = !!this.auth.userToken;

    if (hasToken && protectedRoute)
      return true;

    if (!hasToken && unprotectedRoute)
      return true;

    return void await this.router.navigate([routeToRedirect], {
      replaceUrl: true,
      queryParams: { ...state.url && { redirectTo: state.url } },
    });
  }
}
