import { routes } from './routes';

export const environment = {
  production: false,
  stage: false,
  qas: false,
  apiBaseUrl: 'https://api.universidadeuqr.com.br/dev',
  version: '1.33.0',
  config: {
    redirectToWhenAuthenticated: '/uqr/menu',
    redirectToWhenUnauthenticated: '/login',
    sessionTimeout: 2 * 60 * 1000,
    sessionApiUrl: 'https://8p6so8w76k.execute-api.sa-east-1.amazonaws.com/dev',
    sessionCredential: '4987df9eb257312e69ae85c39f8fca597fc1d4bf599f60ddbd84fbf0c37e0156bee52d9417be808810738c686f8ee495',
    iqrWebsiteUrl: 'https://d15fjfygd2ah94.cloudfront.net/',
  },
  errorCodes: {
    forbiddenIqrLogin: 'iqrLoginInUqr',
  },
  routes,
  certificateRankingId: {
    adamantium: 'd6bdcb00-e528-456b-82b5-ed6cf79c578c',
    vibranium: 'f3ab5d23-d4a4-4740-bef7-c1a54d8ff51b',
    osmium: 'cadee2ac-1337-46d7-9a31-0294147db05b|57414206-daba-4f0e-9755-8427e900d9ac'
  },
  social: {
    facebook: 'https://www.facebook.com/institutoqr/',
    youtube: 'https://www.youtube.com/c/GuilhermeMachadoQuebreAsRegras',
    instagram: 'https://www.instagram.com/institutoqr/',
    linkedin: 'https://www.linkedin.com/company/institutoqr/mycompany/',
  },
  keys: {
    accessCode: 'ACCESS_CODE_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    token: 'USER_TOKEN',
    user: 'USER_INFO',
    bot: 'BOT_INFO',
    userPower: 'POWERS',
  },
  sentry: {
    dns: 'https://4dcffef1738047959f45af762a010745@sentry.ligafacens.com/103',
    server: 'https://sentry.ligafacens.com/api',
    release: '1.14.8',
    tags: {
      framework: 'angular',
      provider: 'liga',
      type: 'app',
      name: 'liga.web.angular.uqr',
    },
    feedback: {
      lang: 'pt-BR',
      title: 'Parece que estamos tendo alguns problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'E-mail',
      labelComments: 'O que aconteceu?',
      labelSubmit: 'Enviar',
      labelClose: 'Fechar',
      errorGeneric: 'Ocorreu um erro desconhecido ao enviar o seu feedback. Por favor, tente novamente.',
      errorFormEntry: 'Alguns campos são inválidos. Corrija os erros e tente novamente.',
      successMessage: 'Seu feedback foi enviado. Obrigado!',
    },
  },
};
