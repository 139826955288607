//#region Imports

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

//#endregion

@Component({
  selector: 'uqr-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {

  //#region Constructors

  constructor(
    private readonly modalController: ModalController,
  ) { }

  //#endregion

  //#region Properties

  @Input()
  public title: string = 'Oops!';

  @Input()
  public message: string = '';

  @Input()
  public icon: string = 'assets/imgs/error-icon.png';

  @Input()
  public isModalQuiz: boolean = false;

  @Output()
  public showModalAnswers: EventEmitter<void> = new EventEmitter<void>();

  //#endregion

  //#region Functions

  public async closeModal(): Promise<void> {
    if (this.isModalQuiz)
      await this.showModalAnswers.emit();

    await this.modalController.dismiss();
  }

  //#endregion
  
}
