//#region

import { formatDate } from '@angular/common';
import { ErrorModalComponent } from '../modals/error-modal/error-modal.component';
import { ModalController } from '@ionic/angular';

//#endregion

/**
 * Método que espera alguns segundos para continuar executando
 */
export async function delay(milliseconds: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function centerAndWrapText(text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = '';

  for (const word of words) {
    const potentialLine = currentLine === '' ? word : `${currentLine} ${word}`;
    if (potentialLine.length <= maxWidth) {
      currentLine = potentialLine;
    } else {
      lines.push(currentLine.trim());
      currentLine = word;
    }
  }

  lines.push(currentLine.trim());

  const centeredLines = lines.map(line => {
    const spacesCount = maxWidth - line.length;
    const leftSpaces = Math.floor(spacesCount / 2);
    const rightSpaces = spacesCount - leftSpaces;
    return ' '.repeat(leftSpaces) + line + ' '.repeat(rightSpaces);
  });

  return centeredLines.join('\n');
}

export function wrapText(text, maxWidth) {
  const words = text.split(' ');
  let currentLine = '';
  let wrappedText = '';

  for (const word of words) {
    if (currentLine.length + word.length <= maxWidth) {
      currentLine += word + ' ';
    } else {
      wrappedText += currentLine.trim() + '\n';
      currentLine = word + ' ';
    }
  }

  wrappedText += currentLine.trim();
  return wrappedText;
}

export function convertSecondsToTime(sec: number): string {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = Math.floor(sec % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  if (hours > 0)
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function generateRandomCode(size: number): string {
  let randomCode = '';

  const allowedCaracters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < size; i++) {
    const caracterIndex = Math.floor(Math.random() * allowedCaracters.length);
    randomCode += allowedCaracters.charAt(caracterIndex);
  }

  return randomCode;
}

export function getVimeoIdByUrl(url: string): string {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/;
  const match = url.match(regex);

  if (match)
    return match[1].split('/')[1];

  throw new Error('Esse não é um link do Vimeio, tente novamente!');
}

/**
 * Método que retorna o valor com um zero a esquerda se necessário
 *
 * @param num O número que será formatado
 */
export function getLeftPad(num: number): string {
  return num <= 9 ? `0${num}` : `${num}`;
}

export function safeUrlWithoutCredentials(originalUrl: string): string {
  if (originalUrl.includes('?X-Amz'))
    return originalUrl.split('?X-Amz')[0];

  return originalUrl;
}

export function getCrudErrors({ status, error }: any): string[] {
  if (status >= 500 && status <= 599)
    return ['Ocorreu um erro interno, por favor, tente novamente.'];

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return ['A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.'];

    return [error.message || 'Ocorreu um erro inesperado, por favor, contate os administradores se o erro persistir.'];
  }

  if (error.message.every(message => typeof message === 'string'))
    return error.message;

  // @ts-ignore
  return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[]);
}

/**
 * Método que retorna o valor sem referências
 *
 * @param mock O valor a ser transformado
 */
export function noRef<T>(mock: T): T {
  if (['string', 'number'].includes(typeof mock))
    return mock;

  return JSON.parse(JSON.stringify(mock));
}

export function getErrorMessage({ status, error }: any): string {
  if (status === 503)
    return error?.message || 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';

  if (status >= 500 && status <= 599)
    return 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return 'A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.';

    return error.message || 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';
  }

  if (error.message.every(message => typeof message === 'string'))
    return error.message[0];

  // @ts-ignore
  return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[])[0];
}

export function isValidEmail(email: string): boolean {
  const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  return regex.test(email);
}

export function isValidPassword(password: string): boolean {
  return password && password.trim().length >= 6;
}

export function pastTime(date: Date): string {
  const secondsInAnHour: number = 60 * 60;
  const secondsInADay: number = 24 * secondsInAnHour;

  const now: Date = new Date();
  
  date = new Date(date);
  
  let timeUnit: string = 'seg';
  const passedTimeInSeconds: number = (now.valueOf() - date.valueOf()) / 1_000;
  let passedTime: number = 0


  if (passedTimeInSeconds < 0)
    throw new Error('Data invalida');

  if (passedTimeInSeconds >= 60 && passedTimeInSeconds < secondsInAnHour) {
    timeUnit = 'min';
    passedTime = passedTimeInSeconds / 60;
  }

  if (passedTimeInSeconds >= secondsInAnHour && passedTimeInSeconds < secondsInADay) {
    timeUnit = 'hora(s)';
    passedTime = passedTimeInSeconds / secondsInAnHour;
  }

  if (passedTimeInSeconds >= secondsInAnHour && passedTimeInSeconds < secondsInADay * 7) {
    timeUnit = 'dia(s)';
    passedTime = passedTimeInSeconds / secondsInADay;
  }

  if (passedTimeInSeconds >= secondsInADay * 7) {
    timeUnit = 'sem.';
    passedTime = passedTimeInSeconds / (secondsInADay * 7);
  }

  if (passedTimeInSeconds >= secondsInADay * 30)
    return formatDate(date, 'dd/MM/yyyy', 'pt-BR').toString();

  return `Há ${Math.round(passedTime)} ${timeUnit}`;
}

export const sortObjects = (array: any[], callback: (...args: any[]) => any) => {
  let i: number;
  let j: number;

  /**
   * BubbleSort
   */
  for (i = 0; i < array.length; i++) {
    for (j = i + 1; j < array.length; j++) {
      if (callback(array[i], array[j])) {
        const aux = array[i];
        array[i] = array[j];
        array[j] = aux;
      }
    }
  }
};

export async function showAlertModal(modalController: ModalController, title: string, message: string): Promise<HTMLIonModalElement> {
  return modalController.create({
    cssClass: 'modal-error',
    component: ErrorModalComponent,
    animated: false,
    componentProps: {
      title,
      message,
      icon: '',
    },
  });
}
