//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ChangeAvatarPayload } from '../../models/payloads/change-avatar.payload';
import { LoginPayload } from '../../models/payloads/login.payload';
import { UserPayload } from '../../models/payloads/user.payload';
import { ForgotPasswordProxy } from '../../models/proxies/forgot-password.proxy';
import { GetUserProgressRanking } from '../../models/proxies/get-user-progress-ranking.proxy';
import { OrganizationProxy } from '../../models/proxies/organization.proxy';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserRankingProxy } from '../../models/proxies/user-ranking.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthInteractor {

  //#region Constructor

  constructor(
    private readonly http: HttpAsyncService,
  ) {
  }

  //#endregion

  //#region Public Methods

  public async performOrganizationLogin(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.http.post<TokenProxy>(environment.routes.auth, payload);
  }

  public async changeUser(payload: UserPayload): Promise<AsyncResult<boolean>> {
    return await this.http.post(environment.routes.changeUser, payload);
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.http.get<UserProxy>(environment.routes.userMe);
  }

  public async getOrganization(organizationId: string): Promise<AsyncResult<OrganizationProxy>> {
    return await this.http.get<OrganizationProxy>(environment.routes.organization + '/' + organizationId);
  }

  public async forgotPassword(email: string): Promise<AsyncResult<ForgotPasswordProxy>> {
    return await this.http.post<ForgotPasswordProxy>(environment.routes.forgotPassword.replace('{email}', email), {});
  }

  public async changeAvatar(payload: ChangeAvatarPayload): Promise<AsyncResult<boolean>> {
    return await this.http.post<boolean>(environment.routes.changeAvatar, payload);
  }

  public async changePassword(password: string): Promise<AsyncResult<void>> {
    return await this.http.post(environment.routes.changePassword, { password });
  }

  public async  setHasSeenAsTruePatentInfo(userId: string): Promise<AsyncResult<void>> {
    return await this.http.put(`${ environment.routes.user }/${ userId }`, { hasSeenPatentsInfo: true });
  }

  public async setHasSeenSelectTagsAsTrue(userId: string): Promise<AsyncResult<void>> {
    return await this.http.put(`${ environment.routes.user }/${ userId }`, { hasSeenSelectTags: true });
  }

  public async acceptTerms(): Promise<AsyncResult<void>> {
    return await this.http.post(environment.routes.acceptTerms, {});
  }

  public async setRecommendation(recomendation: number): Promise<AsyncResult<void>> {
    return await this.http.post(environment.routes.recomendation, { recomendation });
  }

  public async getMyRankingInfo(): Promise<AsyncResult<GetUserProgressRanking>> {
    return await this.http.get<GetUserProgressRanking>('/users/progress/ranking-patent/me');
  }

  public async getMyRanking(): Promise<AsyncResult<UserRankingProxy>> {
    return await this.http.get<UserRankingProxy>('/users/ranking/me');
  }

  public async updateAmountHoursToStudy(amountHoursToStudy: string): Promise<AsyncResult<void>> {
    return await this.http.put<void>(environment.routes.amountHours, { amountHoursToStudy });
  }

  public async update(userId: string, payload: Partial<UserPayload>): Promise<AsyncResult<void>> {
    return await this.http.put<void>('/users/' + userId, payload);
  }

  //#endregion

}
