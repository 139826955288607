//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from '../../../services/auth/auth.service';
import { StorageService } from '../../../services/storage/storage.service';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

/**
 * A classe que representa o interceptor que adiciona o header de Authorization para todas as requisições
 */
@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    protected readonly authService: AuthService,
    protected readonly storage: StorageService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) { }

  //#endregion

  //#region Public Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-BearerToken';

  //#endregion

  //#region Public Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config?.bearerTokenKey) {
      console.warn('Você incluiu o Interceptor para adicionar o Bearer Token a requisição mas não configurou a chave para buscar o valor do cache no módulo.');
      return next.handle(req);
    }

    if (!req.headers.get(BearerTokenInterceptor.DISABLE_HEADER)) {
      return fromPromise(this.authService.getToken())
        .pipe(
          mergeMap(result => {
            if (!result.success)
              return next.handle(req);

            const headers = req.headers.set('Authorization', result.success.token);

            req = req.clone({
              headers,
            });

            return next.handle(req);
          })
        );
    }

    req = req.clone({
      headers: req.headers.delete(BearerTokenInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion

}
