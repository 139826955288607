//#region Imports

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http-async/interceptors/bearer-token.interceptor';
import { CodeAccessInterceptor } from './modules/http-async/interceptors/code-access.interceptor';
import { HttpAsyncHeadersInterceptor } from './modules/http-async/interceptors/http-async-headers.interceptor';
import { PlatformInterceptor } from './modules/http-async/interceptors/platform.interceptor';
import { ForbiddenInterceptor } from './modules/http-async/interceptors/refresh-token-interceptor.service';
import { RetryInterceptor } from './modules/http-async/interceptors/retry.interceptor';
import { IsOfflineInterceptor } from './modules/network/interceptors/is-offline.interceptor';
import { NetworkModule } from './modules/network/network.module';
import { createErrorHandler } from './modules/sentry/error-handler';
import { TraceService } from './modules/sentry/tracing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxMaskModule } from 'ngx-mask';
import localePT from '@angular/common/locales/pt';

//#endregion

registerLocaleData(localePT);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NguCarouselModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      dbKey: '__uqrdb',
      driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
    }),
    HttpAsyncModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      defaultHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      bearerTokenKey: environment.keys.token,
    }),
    NetworkModule,
    NgbModule,
    LottieModule.forRoot({player: playerFactory}),
  ],
  providers: [
    Network,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: !environment.production,
        dialogOptions: environment.sentry.feedback,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: IsOfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CodeAccessInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PlatformInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAsyncHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-br' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function playerFactory() {
  return player;
}
