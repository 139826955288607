//#region Imports

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthInteractor } from '../../interactors/auth/auth.interactor';
import { ChangeAvatarPayload } from '../../models/payloads/change-avatar.payload';
import { LoginPayload } from '../../models/payloads/login.payload';
import { UserPayload } from '../../models/payloads/user.payload';
import { GetUserProgressRanking } from '../../models/proxies/get-user-progress-ranking.proxy';
import { OrganizationProxy } from '../../models/proxies/organization.proxy';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserRankingProxy } from '../../models/proxies/user-ranking.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { getCrudErrors, getErrorMessage } from '../../utils/functions';
import { MenuUQRService } from '../menuuqr/menu-uqr.service';
import { StorageAsyncResult, StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  //#region Constructor

  constructor(
    private readonly interactor: AuthInteractor,
    private readonly storage: StorageService,
    private readonly menuUQRService: MenuUQRService,
  ) { }

  //#endregion

  //#region Public Properties

  public user: UserProxy = null;

  public userToken: TokenProxy = null;

  //#endregion

  //#region Private Properties

  private readonly onChangeAuthentication: BehaviorSubject<UserProxy | undefined> = new BehaviorSubject<UserProxy | undefined>(void 0);

  private readonly rankingUser$: BehaviorSubject<GetUserProgressRanking | undefined> = new BehaviorSubject<GetUserProgressRanking | undefined>({
    rankUser: undefined,
    data: [],
    page: 0,
    total: 0,
    pageCount: 0,
    count: 0,
  });

  //#endregion

  //#region Public Methods

  public async setupAuthentication(): Promise<void> {
    const { error, success } = await this.storage.getItem<UserProxy>(environment.keys.user);

    if (error || !success)
      return;

    Sentry.setUser({ id: success.id?.toString(), email: success.email, username: success.username });

    if (this.onChangeAuthentication.getValue()?.id === success.id)
      return;

    this.onChangeAuthentication.next(success);
  }

  public async logout(): Promise<void> {
    this.onChangeAuthentication.next(void 0);
    this.menuUQRService.setCurrentInputTextMenu(void 0);
    this.userToken = null;

    await this.storage.remove(environment.keys.user);
    await this.storage.remove(environment.keys.token);
    await this.storage.remove(environment.keys.userPower);
    await this.storage.remove(environment.keys.refreshToken);
  }

  public async getToken(): Promise<StorageAsyncResult<TokenProxy>> {
    const token = await this.storage.getItem<TokenProxy>(environment.keys.token);

    if (token.success)
      return token;

    if (this.userToken)
      return { success: this.userToken };

    return token;
  }

  public async performLogin(payload: LoginPayload, keepConnected: boolean): Promise<[boolean, TokenProxy | string]> {
    Sentry.setUser({
      username: payload.username,
      email: payload.username,
    });

    const { error: error, success: token } = await this.interactor.performOrganizationLogin(payload);
    
    if (error && error.error.errorCode === environment.errorCodes.forbiddenIqrLogin)
      return [false, error.error.errorCode];

    if (error)
      return [false, getCrudErrors(error)[0]];

    if (keepConnected)
      await this.storage.setItem(environment.keys.token, token);
    else
      this.userToken = token;

    return [true, token];
  }

  public async getMe(keepConnected?: boolean): Promise<UserProxy> {
    const { error, success } = await this.interactor.getMe();

    if (error)
      return;

    const referenceUser = await this.storage.getItem<UserProxy>(environment.keys.user);

    if (keepConnected || referenceUser.success)
      await this.storage.setItem<UserProxy>(environment.keys.user, success);
    else
      this.user = success;

    this.setCurrentUser$(success);
    return success;
  }

  public async getOrganization(organizationId: string): Promise<OrganizationProxy> {
    const { error, success } = await this.interactor.getOrganization(organizationId);

    if (error)
      return;

    return success;
  }

  public async forgotPassword(email: string): Promise<[boolean, string]> {
    const { error, success } = await this.interactor.forgotPassword(email);

    if (error)
      return [false, getErrorMessage(error)];

    return [success.isEmailSent, 'Sucesso, prossiga com os proximos passos a partir do e-mail enviado.'];
  }

  public async changeAvatar(payload: ChangeAvatarPayload): Promise<[boolean, string]> {
    const { error, success } = await this.interactor.changeAvatar(payload);

    if (error)
      return [false, getErrorMessage(error)];

    return [success, 'O avatar foi alterado com sucesso!'];
  }

  public async changeUser(payload: UserPayload): Promise<[boolean, string]> {
    const { error, success } = await this.interactor.changeUser(payload);

    if (error)
      return [false, getErrorMessage(error)];

    return [success, 'O usuario foi alterado com sucesso!'];
  }

  public async changePassword(password: string): Promise<[boolean, string]> {
    const { error } = await this.interactor.changePassword(password);

    if (error)
      return [false, getErrorMessage(error)];

    return [true, 'A senha foi alterada com sucesso!'];
  }

  public async setHasSeenAsTruePatentInfo(userId: string): Promise<void> {
    await this.interactor.setHasSeenAsTruePatentInfo(userId);
  }

  public async setHasSeenSelectTagsAsTrue(userId: string): Promise<void> {
    await this.interactor.setHasSeenSelectTagsAsTrue(userId);
  }

  public getCurrentUser(): UserProxy {
    return this.onChangeAuthentication.getValue() as UserProxy;
  }

  public getCurrentUser$(): Observable<UserProxy> {
    return this.onChangeAuthentication.asObservable();
  }

  public setCurrentUser$(user: UserProxy) {
    return this.onChangeAuthentication.next(user);
  }

  public async acceptTerms(): Promise<[boolean, string]> {
    const { error } = await this.interactor.acceptTerms();

    if (error)
      return [false, getErrorMessage(error)];

    return [true, 'O termo foi aceito com sucesso!'];
  }

  public async setRecommendation(recommendation: number): Promise<[boolean, string]> {
    const { error } = await this.interactor.setRecommendation(recommendation);

    if (error)
      return [false, getErrorMessage(error)];

    return [true, 'A recomendação foi enviada com sucesso!'];
  }

  public getCurrentRankingUser$(): Observable<GetUserProgressRanking> {
    return this.rankingUser$.asObservable();
  }

  public async getMyRankingInfo(): Promise<GetUserProgressRanking> {
    const { error, success } = await this.interactor.getMyRankingInfo();

    if (error)
      return;

    this.rankingUser$.next(success);
    return success;
  }

  public async getMyRanking(): Promise<UserRankingProxy> {
    const { error, success } = await this.interactor.getMyRanking();

    if (error)
      return;

    return success;
  }

  public async updateHoursToStudy(amountHoursToStudy: string): Promise<void> {
    const { error } = await this.interactor.updateAmountHoursToStudy(amountHoursToStudy);

    if (error)
      throw new Error(error.error.message);
  }

  public async update(userId: string, payload: Partial<UserPayload>): Promise<[boolean, string]> {
    const { error } = await this.interactor.update(userId, payload);

    if (error)
      return [false, 'Erro ao editar usuário!'];

    return [true, 'Usuário alterado com sucesso!'];
  }

  //#endregion

}
