//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { mergeMap } from 'rxjs/operators';
import { SingleAccessService } from '../../../services/single-access/single-access.service';
import { StorageService } from '../../../services/storage/storage.service';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class CodeAccessInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    protected readonly singleAccessService: SingleAccessService,
    protected readonly storage: StorageService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) { }

  //#endregion

  //#region Public Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-CodeAccesss';

  //#endregion

  //#region Public Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.headers.get(CodeAccessInterceptor.DISABLE_HEADER)) {
      return fromPromise(this.singleAccessService.getCurrentAccessCode())
        .pipe(
          mergeMap(result => {
            if (!result.success)
              return next.handle(req);

            const headers = req.headers.set('X-Code-Access', result.success.accessCode);

            req = req.clone({
              headers,
            });

            return next.handle(req);
          })
        );
    }

    req = req.clone({
      headers: req.headers.delete(CodeAccessInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion

}
