//#region Imports

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

import { AuthenticateGuard } from './guards/authenticate/authenticate.guard';

//#endregion

export const unAuthenticatedRoute = { canActivate: [AuthenticateGuard], data: { routeToRedirect: environment.config.redirectToWhenAuthenticated, unprotectedRoute: true } };

export const authenticatedRoute = { canActivate: [AuthenticateGuard], data: { routeToRedirect: environment.config.redirectToWhenUnauthenticated, protectedRoute: true } };

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'uqr', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), ...authenticatedRoute },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), ...unAuthenticatedRoute },
  { path: '**', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), ...authenticatedRoute },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
