//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SingleAccessInteractor } from '../../interactors/single-access/single-access.interactor';
import { generateRandomCode } from '../../utils/functions';
import { StorageAsyncResult, StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class SingleAccessService {

  //#region Constructors

  constructor(
    private readonly singleAccessInteractor: SingleAccessInteractor,
    private readonly storage: StorageService,
  ) {}

  public async genareteSigleAccessCode(): Promise<void> {
    const accessCode = await this.getCurrentAccessCode();

    if (accessCode.success)
      return;

    const randomCode = generateRandomCode(10);
    await this.storage.setItem(environment.keys.accessCode, { accessCode: randomCode });
  }

  public async setNewSingleAccessCode(): Promise<void> {
    const accessCode = await this.getCurrentAccessCode();
    await this.singleAccessInteractor.setNewAccessCode(accessCode.success.accessCode);
  }

  public async verifySigleAccessCode(): Promise<void> {
    const code = await this.getCurrentAccessCode();

    if (!code)
      await this.genareteSigleAccessCode();

    const { error } = await this.singleAccessInteractor.verifySigleAccessCode();

    if (error)
      throw new Error('Tem que logar novamente amigão');
  }

  public async getCurrentAccessCode(): Promise<StorageAsyncResult<{ accessCode: string }>> {
    return await this.storage.getItem<{ accessCode: string }>(environment.keys.accessCode);
  }

  //#endregion

}
